import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Ahats() {
  return (
    <Layout>
      <GetItems search="Adult Fitted Hats" title="Adult Fitted Hats" />
    </Layout>
  )
}

export const Head = () => (<Seo pathname={'./accessories/afhats'} title="STM Quality Adult Fitted Hats: Positivity and Awareness" description={"Shopping for adult fitted hats to promote Stronger Than Medicine (STM)."} />)
